import React from 'react';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectUserStats } from '@store/meStats/selector';
import { selectDisplayMenu } from '@store/myKpHeader/selector';
import { setDisplayMenu } from '@store/myKpHeader/slice';

import Badge from '@components/common/responsive/Badge/Badge';
import KpMonogram from '@components/common/responsive/KpMonogram/KpMonogram';
import Button from '@components/controls/Button/Button';
import NavigationMenuIconMedium from '@components/icons/functions/NavigationMenuIconMedium';

import style from './MyKpMenuButton.module.scss';

const MyKpMenuButton = () => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectDisplayMenu);

  const { isKpRenewerExpireSoonVisible, isKpRenewerExpiredVisible } =
    useAppSelector(selectUserStats);

  const onOpen = () => {
    if (!isOpen) {
      dispatch(setDisplayMenu(true));
    }
  };

  return (
    <Button
      ariaLabel="Moj kp"
      noPadding
      onClick={onOpen}
      leading={
        <span className={style.menuIconButton}>
          <Badge
            red
            furtherRight
            content={
              isKpRenewerExpireSoonVisible || isKpRenewerExpiredVisible
                ? '!'
                : ''
            }
          >
            <NavigationMenuIconMedium
              stroke={COLOR_TYPES.contentInteractiveSecondary}
            />
          </Badge>
          <p>
            Moj <KpMonogram />
          </p>
        </span>
      }
    />
  );
};

export default MyKpMenuButton;
