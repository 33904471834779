import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import Header from '@layouts/responsive/Header/Header';

import MyKpMenuButton from '@components/common/mobile/MyKpMenu/MyKpMenuButton/MyKpMenuButton';
import Button from '@components/controls/Button/Button';
import ArrowLeftIconMedium from '@components/icons/functions/ArrowLeftIconMedium';

import style from './MobileTopHeader.module.scss';

const setClass = classNames.bind(style);

export interface MobileTopHeaderProps {
  children?: React.ReactNode;
  kpBorder?: boolean;
  trailing?: React.ReactNode;
  leading?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  hasShadow?: boolean;
  showLogo?: boolean;
  useBack?: boolean;
  showKpMenu?: boolean;
  isFixed?: boolean;
  canHaveRapidMessage?: boolean;
}

const MobileTopHeader = ({
  children,
  leading,
  trailing,
  kpBorder = false,
  onClick,
  className,
  hasShadow = true,
  showLogo = false,
  useBack = false,
  showKpMenu = false,
  isFixed = false,
  canHaveRapidMessage = false,
}: MobileTopHeaderProps) => {
  const router = useRouter();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(event);
    } else if (window.history.length > 1) {
      router.back();
    } else if (window.parent) {
      window.close();
    }
  };

  return (
    <Header
      kpBorder={kpBorder}
      showLogo={showLogo}
      classNameCenter={style.title}
      className={className}
      hasShadow={hasShadow && !kpBorder}
      isFixed={isFixed}
      canHaveRapidMessage={canHaveRapidMessage}
    >
      <Header.Left>
        {!leading && useBack && (
          <Button
            ariaLabel="Idi nazad"
            className={setClass({ back: useBack })}
            onClick={handleClick}
            leading={
              <ArrowLeftIconMedium
                stroke={COLOR_TYPES.contentInteractiveSecondary}
              />
            }
          />
        )}
        {leading && leading}
      </Header.Left>
      <Header.Center>{children}</Header.Center>
      <Header.Right>
        {trailing && trailing}
        {showKpMenu && <MyKpMenuButton />}
      </Header.Right>
    </Header>
  );
};

export default MobileTopHeader;
