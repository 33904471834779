import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const NavigationMenuIconMedium = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2 11.5H14" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 7.5H14" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 3.5H14" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default asIcon(NavigationMenuIconMedium);
