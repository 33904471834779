import React from 'react';
import classNames from 'classnames/bind';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import style from './Badge.module.scss';

const setClass = classNames.bind(style);

type BadgeProps = {
  children: React.ReactNode & any;
  content?: number | string;
  bottom?: boolean;
  green?: boolean;
  red?: boolean;
  grey?: boolean;
  lightBlue?: boolean;
  blue?: boolean;
  left?: boolean;
  className?: string;
  ariaHidden?: boolean;
  furtherRight?: boolean;
};

const Badge = ({
  children,
  content = 0,
  bottom = false,
  left = false,
  green = false,
  grey = false,
  red = true,
  lightBlue = false,
  blue = false,
  className = '',
  ariaHidden,
  furtherRight = false,
}: BadgeProps) => {
  const maxValue = 99;
  const displayValue = Number(content) > maxValue ? `${maxValue}+` : content;
  return (
    <span
      aria-hidden={ariaHidden}
      className={`${style.badgeHolder} ${className}`}
    >
      {children}
      <span
        className={setClass({
          badgeContent: true,
          left,
          lightBlue,
          blue,
          red,
          furtherRight,
          hide: Number(content) === 0,
        })}
      >
        {displayValue}
      </span>
      {bottom && (
        <span className={style.bottom}>
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={setClass({ green, grey })}
          >
            <circle cx="4" cy="4" r="3.5" stroke={COLOR_TYPES.borderNeutral} />
          </svg>
        </span>
      )}
    </span>
  );
};

export default Badge;
